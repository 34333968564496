import { setValue, getValue, isTokenExpired
  // , calculateLogoutTime
 } from "../utils/sessionManager";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pulseDbAuthToken: getValue("pulseDbAuthToken"),
  pulseDbTokenExpirationTime: getValue("pulseDbTokenExpirationTime"),
  isPulseDbTokenExpired: isTokenExpired("pulseDbTokenExpirationTime"),
};

const pulseDbAuthSlice = createSlice({
  name: "pulsedbAuth",
  initialState,
  reducers: {
    setPulseDbAuthToken(state, action) {
      state.pulseDbAuthToken = action.payload;
      state.isPulseDbTokenExpired = true;
      setValue("pulseDbAuthToken", action.payload);
      setValue("isPulseDbTokenExpired", true);
      // expiration time =30 minutes
      let expectedExpirationTime = new Date(new Date().getTime() + 30 * 60 * 1000).getTime();
      state.pulseDbTokenExpirationTime = expectedExpirationTime;
      setValue("pulseDbTokenExpirationTime", expectedExpirationTime);

      // state.isPulseDbTokenExpired = isPulseDbTokenExpired;
    },
    removePulseDbAuthToken(state) {
      state.pulseDbAuthToken = null;
      state.pulseDbTokenExpirationTime = null;
      state.isPulseDbTokenExpired = false;
    },
  },
});

export const { setPulseDbAuthToken, removePulseDbAuthToken } = pulseDbAuthSlice.actions;

export default pulseDbAuthSlice.reducer;
