import {
  setValue,
  // removeValue,
  getValue,
  isTokenExpired,
  // calculateLogoutTime,
} from "../utils/sessionManager";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alchemyServiceAuthToken: getValue("alchemyServiceAuthToken"),
  alchemyServiceTokenExpirationTime: getValue("alchemyServiceTokenExpirationTime"),
  isAlchemyServiceTokenExpired: isTokenExpired("alchemyServiceTokenExpirationTime"),
};

//authTokenSlice

const alchemyServiceAuthSlice = createSlice({
  name: "alchemyServiceAuth",
  initialState,
  reducers: {
    setAlchemyServiceAuthToken(state, action) {
      state.alchemyServiceAuthToken = action.payload;
      state.isAlchemyServiceTokenExpired = true;
      setValue("alchemyServiceAuthToken", action.payload);
      setValue("isAlchemyServiceTokenExpired", true);
      // expiration time =30 minutes
      let expectedExpirationTime = new Date(new Date().getTime() + 30 * 60 * 1000).getTime();
      state.alchemyServiceTokenExpirationTime = expectedExpirationTime;
      setValue("alchemyServiceTokenExpirationTime", expectedExpirationTime);
    },
    removeAlchemyServiceAuthToken(state) {
      state.alchemyServiceAuthToken = null;
      state.alchemyServiceTokenExpirationTime = null;
      state.isAlchemyServiceTokenExpired = false;
    },
  },
});

export const {
  setAlchemyServiceAuthToken,
  removeAlchemyServiceAuthToken,
} = alchemyServiceAuthSlice.actions;

export default alchemyServiceAuthSlice.reducer;
