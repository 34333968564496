import axios from "axios";
import { getValue } from "../utils/sessionManager";
// import store from "../store";

// api calls

// Function to add current URL and client_id based on method
const addCurrentUrl = (method, data,url) => {
  const user = getValue("user") || {};
  
  if (user.isLogedIn && url.includes("api-mf")) {
    const commonData = {
      current_url: window.location.href,
      log_client_id: user.client_id,
      log_role: user.role,
      log_user_id: user.user_id.toString()
    };
    
    if (["post", "put", "delete"].includes(method.toLowerCase())) {
      return { data: { ...data, ...commonData } };
    }
    return { headers: { ...commonData } };
  }
  
  return { data: { ...data } };
};

// Generic API call function
export const apiCalls = (method, url, data = {}) => {
  const { data: requestData, headers } = addCurrentUrl(method, data,url);
  
  return axios({
    method,
    url,
    ...(method.toLowerCase() === "get" ? { params: data, headers } : { data: requestData, headers })
  });
};

// Authenticated API call function
export const authApiCalls = (method, url, data = {}) => {
  const { data: requestData, headers: customHeaders } = addCurrentUrl(method, data,url);
  const headers = { ...customHeaders, auth: getValue("authTokenOne") || "" };
  
  return axios({
    method,
    url,
    ...(method.toLowerCase() === "get" ? { params: data, headers } : { data: requestData, headers })
  });
};

// export const authApiCalls = (method, url, data = {}) => {
//   const headers = { auth: getValue("token") || "fake_token" };
//   if (method === "get") {
//     // only for get
//     return axios({ method: method, url: url, params: data, headers: headers });
//   } else {
//     // post, put, delete
//     return axios({ method: method, url: url, data: data, headers: headers });
//   }
// };

// auth api calls with Header

// Authenticated API call function with custom headers
export const authApiHeader = (method, url, headerdata, data = {}) => {
  const { data: requestData, headers: customHeaders } = addCurrentUrl(method, data,url);
  const headers = { ...headerdata, ...customHeaders };
  
  return axios({
    method,
    url,
    headers,
    ...(method.toLowerCase() === "get" ? { params: data } : { data: requestData })
  });
};
// Added axios global variable
// axios.interceptors.request.use(
//   config => {
//     const token = getUserToken();

//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }

//     return config;
//   },
//   error => Promise.reject(error)
// );
// axios.defaults.headers.post["Content-Type"] = "application/json";
