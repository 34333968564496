import React, { Fragment } from 'react';
import './Loader.scss';

const ContentLoader = () => (
  <Fragment>
    <div className="content_loader"></div>
  </Fragment>
);

export default ContentLoader;

// Ex: how to use
// <ContentLoader />

