import React, { Fragment } from "react";
import PreloginAdvisorHeader from "../components/Header/PreloginAdvisorHeader";


const AdvisorMainLayout = (props) => {
  return (
    <Fragment>
      <PreloginAdvisorHeader   />

      <div>{props.children}</div>
    </Fragment>
  );
};

export default AdvisorMainLayout;
