import React, { Fragment } from "react";

import NotVerifiedDomainHeader from "../components/Header/NotVerifiedDomainHeader";

const SubdomainNotVerifiedMainLayout = (props) => {
  return (
    <Fragment>
      <NotVerifiedDomainHeader />
      <div>{props.children}</div>
    </Fragment>
  );
};

export default SubdomainNotVerifiedMainLayout;
