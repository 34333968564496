import React, { Fragment, Component } from "react";
import Button from "../../components/Button/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import config from "../../config";
import { getValue } from "../../utils/sessionManager";

// import { uploadFiles } from "../../actions/reportsAction";
import axios from "axios";
class UploadRtas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "CAMS",
      showMoreGuide: false,
      showUpload: false,
      uploadPercentage: 0,
      uploadStatus: "",
      selectedFile: "",
      isLoading: false,
    };
  }

  handleClickTab = (type) => {
    this.setState({ activeTab: type, uploadStatus: "", isLoading: false });
  };

  showUpload = () => {
    this.setState({
      showUpload: true,
      showMoreGuide: false,
    });
  };

  showMore = () => {
    this.setState({
      showMoreGuide: !this.state.showMoreGuide,
    });
  };

  handleSelectedFile = ({ target: { files } }) => {
    // console.log(files[0]);
    this.setState({ selectedFile: files[0], uploadStatus: "" });
  };

  // uploadFile = () => {
  //   const { selectedFile } = this.state;
  //   let Data = new FormData();
  //   Data.append("file", selectedFile);

  //   const uploadProgress = {
  //     onUploadProgress: (progressEvent) => {
  //       const { loaded, total } = progressEvent;
  //       console.log(loaded);
  //       let percent = Math.floor((loaded * 100) / total);
  //       if (percent < 100) {
  //         this.setState({ uploadPercentage: percent, isLoading: true }, () => this.submitForm());
  //       }
  //     },
  //   };
  // };

  // submitForm = async () => {
  //   const { selectedFile } = this.state;
  //   let Data = new FormData();
  //   Data.append("file", selectedFile);
  //   try {
  //     let res = await axios.post(url, Data, uploadProgress);

  //     // let res = await uploadFiles(Data,options);
  //     // console.log(res.data.status.message);
  //     let uploadMessage = res.data.status.message;
  //     if (uploadMessage === "OK") {
  //       this.setState({ uploadPercentage: 100 }, () => {
  //         setTimeout(() => {
  //           this.setState({ uploadPercentage: 0, uploadStatus: "success", isLoading: false });
  //         }, 1000);
  //       });
  //     } else if (uploadMessage === "Failure") {
  //       this.setState({ uploadStatus: "failed", isLoading: false });
  //     }
  //   } catch (err) {
  //     // console.log(err);
  //     // this.setState({uploadStatus:"failed"})
  //     console.error("handleSubmit, Server err: ", err.response);
  //   }
  // };

  // handleSubmit = async () => {
  //   const { selectedFile } = this.state;
  //   let Data = new FormData();
  //   Data.append("file", selectedFile);

  //   const uploadProgress = {
  //     onUploadProgress: (progressEvent) => {
  //       const { loaded, total } = progressEvent;
  //       console.log(loaded);
  //       let percent = Math.floor((loaded * 100) / total);
  //       if (percent < 100) {
  //         this.setState({ uploadPercentage: percent, isLoading: true });
  //       }
  //     },
  //   };

  //   try {
  //     // loding perc not working on existing api call method from action file need to work on it
  //     const url = "";
  //     if (config.node_env === "production") {
  //       url = "https://alchemy-service-prod.pulselabs.co.in/rta-upload/upload";
  //     } else {
  //       url = "https://alchemy-service.pulselabs.co.in/rta-upload/upload";
  //     }
  //     let res = await axios.post(url, Data, uploadProgress);

  //     // let res = await uploadFiles(Data,options);
  //     // console.log(res.data.status.message);
  //     let uploadMessage = res.data.status.message;
  //     if (uploadMessage === "OK") {
  //       this.setState({ uploadPercentage: 100 }, () => {
  //         setTimeout(() => {
  //           this.setState({ uploadPercentage: 0, uploadStatus: "success", isLoading: false });
  //         }, 1000);
  //       });
  //     } else if (uploadMessage === "Failure") {
  //       this.setState({ uploadStatus: "failed", isLoading: false });
  //     }
  //   } catch (err) {
  //     // console.log(err);
  //     // this.setState({uploadStatus:"failed"})
  //     console.error("handleSubmit, Server err: ", err.response);
  //   }
  // };

  handleFileUpload = () => {
    if (config.node_env === "production") {
      this.handleSubmitProd();
    } else {
      this.handleSubmitDev();
    }
  };

  handleSubmitDev = async () => {
    const { selectedFile, pwd } = this.state;
    let Data = new FormData();
    Data.append("file", selectedFile);
    Data.append("pwd", pwd);
    const uploadProgress = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          this.setState({ uploadPercentage: percent, isLoading: true });
        }
      },
    };
    try {
      let res = await axios.post(config.base_url + `/rta-upload/upload`, Data);
      let uploadMessage = res.data.status.message;
      if (uploadMessage === "OK") {
        this.setState({ uploadPercentage: 100 }, () => {
          setTimeout(() => {
            this.setState({ uploadPercentage: 0, uploadStatus: "success", isLoading: false });
          }, 1000);
        });
      } else if (uploadMessage === "Failure") {
        this.setState({ uploadStatus: "failed", isLoading: false });
      }
    } catch (err) {
      console.error("handleSubmitDev, Server err: ", err.response);
    }
  };

  handleSubmitProd = async () => {
    const { selectedFile, pwd } = this.state;
    let Data = new FormData();
    Data.append("file", selectedFile);
    Data.append("pwd", pwd);
    const uploadProgress = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          this.setState({ uploadPercentage: percent, isLoading: true });
        }
      },
    };
    try {
      const authTokenOne = getValue("authTokenOne");
      let res = await axios.post(
        "https://alchemy-service-prod.pulselabs.co.in/rta-upload/upload",
        Data,
        { headers: { Authorization: `Bearer ${authTokenOne}` } }
      );
      //console.log(res);
      let uploadMessage = res.data.status.message;
      if (uploadMessage === "OK") {
        this.setState({ uploadPercentage: 100 }, () => {
          setTimeout(() => {
            this.setState({ uploadPercentage: 0, uploadStatus: "success", isLoading: false });
          }, 1000);
        });
      } else if (uploadMessage === "Failure") {
        this.setState({ uploadStatus: "failed", isLoading: false });
      }
    } catch (err) {
      console.error("handleSubmitProd, Server err: ", err.response);
    }
  };

  render() {
    const {
      activeTab,
      showMoreGuide,
      showUpload,
      uploadPercentage,
      uploadStatus,
      isLoading,
    } = this.state;
    return (
      <Fragment>
        <div className="container">
          {showUpload && (
            <Fragment>
                <div className="row justify-content-center">
                  <div className="col-md-11">
                    <div className="row mt-5 border-bottom al_tabs_2" style={{ cursor: "pointer" }}>
                      <span
                        className={`col text-center ${activeTab === "CAMS" ? "active " : ""}`}
                        onClick={() => this.handleClickTab("CAMS")}
                      >
                        CAMS
                      </span>
                      <span
                        className={`col text-center ${activeTab === "Karvy" ? "active" : ""}`}
                        onClick={() => this.handleClickTab("Karvy")}
                      >
                        {" "}
                        Karvy
                      </span>
                      <span
                        className={`col text-center ${activeTab === "FT" ? "active" : ""}`}
                        onClick={() => this.handleClickTab("FT")}
                      >
                        {" "}
                        FT
                      </span>
                      <span
                        className={`col text-center ${activeTab === "BSE" ? "active" : ""}`}
                        onClick={() => this.handleClickTab("BSE")}
                      >
                        {" "}
                        BSE
                      </span>
                    </div>
                </div>
              </div>
              <div className="row justify-content-center mt-5">
                <div className="col-md-10">
                  <div className="row justify-content-between">
                <div className="col-sm-5">
                  <div className="form-group">
                    {activeTab === "CAMS" && <label>WBR 2 (Transaction File)</label>}
                    {activeTab === "Karvy" && <label>MFSD 201 (Transaction File) </label>}
                    {activeTab === "FT" && <label>Transaction File </label>}
                    {activeTab === "BSE" && <label>Client Master Report </label>}
                    <div className="row border border-dark p-1 rounded">
                      <input
                        className="col-9 form-control"
                        type="file"
                        name="file"
                        onChange={this.handleSelectedFile}
                      />
                      <Button
                        text="Submit"
                        size="sm"
                        onClick={() => this.handleFileUpload()}
                        customClass={"col-3 al_button_new_2"}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="form-group">
                    {activeTab === "CAMS" && <label>WBR 9 (Summary File)</label>}
                    {activeTab === "Karvy" && <label>MFSD 203 (Summary File) </label>}
                    {activeTab === "FT" && <label>Summary File </label>}
                    {activeTab === "BSE" && <label>Mandate Report </label>}
                    {/* <label>Upload MFSD203 (Summary File)</label> */}
                    <div className="row border border-dark p-1 rounded">
                      <input
                        className="col-9 form-control"
                        type="file"
                        name="file"
                        onChange={this.handleSelectedFile}
                      />
                      <Button
                        text="Submit"
                        size="sm"
                        onClick={() => this.handleFileUpload()}
                        customClass={"col-3 al_button_new_2"}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </div>
              {uploadPercentage > 0 && (
                <Fragment>
                  <p>Your upload is in progress. Please wait.</p>
                  <ProgressBar now={uploadPercentage} label={`${uploadPercentage}%`} />
                </Fragment>
              )}
              {uploadStatus === "success" && (
                <p className="font-weight-bold par_3 text_clr_4 mt-3">
                  Your files have been successfully processed. Reports will be available the next
                  day.
                </p>
              )}
              {uploadStatus === "failed" && (
                <div className=" font-weight-bold mt-3 par_3">
                  <p className="d-inline  text_clr_5 m-1">
                    There was an error processing your file.
                  </p>
                  <p className="d-inline  text_clr_4 m-1">
                    Please contact support@pulselabs.co.in.
                  </p>
                </div>
              )}
            </Fragment>
          )}

          <div className="mt-5">
            <div className="row justify-content-center">
              <p className="h4 text-nowrap col-10 mb-4">
                Please read through the *guidelines* carefully before uploading the files.
              </p>
              <p className="bg_clr_10 p-3 col-5 mr-2 text_2">We support excel & csv files for upload.</p>
              <p className="bg_clr_10 p-3 col-5  text_2">
                If the headers do not match or if the file format is different, you will get an
                error message. Please contact Support Team during such cases.
              </p>
              <p className="bg_clr_7 p-3 col-5 mr-2  text_2 ">
                Transaction files of RTA are mandatory. In case you would like to do recon, you may
                upload the summary files too.
              </p>
              <p className="bg_clr_7 p-3 col-5  text_2 ">
                While downloading the transaction files from RTA systems, please make sure that you
                do it since the oldest date of the transaction.
              </p>
            </div>
            {showMoreGuide && (
              <div className="row justify-content-center">
                <p className="bg_clr_10 p-3 col-5 mr-2  text_2 ">
                  While downloading the summary files from RTA systems, please ensure date
                  uniformity between all 4 RTAs. That is, the report "as on date" must be the same
                  across all RTAs.
                </p>
                <p className="bg_clr_10 p-3 col-5  text_2 ">
                  In the case of FT, transactions can only be extracted for a max period of 6
                  months. Hence, you will have to upload multiple files.
                </p>
                <p className="bg_clr_7 p-3 col-5 mr-2  text_2 ">
                  Please choose "Excel with Headers" for CAMS & "Excel" for Karvy. Do note to select
                  all AMCs.
                </p>
                <p className="bg_clr_7 p-3 col-5  text_2 ">
                  Please delete the 1st row titled "Transaction Report" from the Karvy file.
                </p>

                <p className="bg_clr_10 p-3 col-5 mr-2  text_2 ">
                  FT does not allow excel download. Hence, please convert the text/dbf files to
                  excel for uploading.
                </p>
                <p className="bg_clr_10 p-3 col-5  text_2 ">
                  You can check out the processing stats under the RTA tab in order to know the
                  transaction dates from which the file is processed.
                </p>
                <p className="bg_clr_7 p-3 col-5 mr-2  text_2 ">
                  A unit recon process will be initiated in order to ensure data accuracy.
                  Exceptions can be found under "recon" tab, where there would have been a mismatch
                  between units in transaction & summary file, for a given folio/scheme combination.
                  Please be aware that the investment reports will not be accurate for these.
                </p>
                <p className="bg_clr_7 p-3 col-5  text_2 ">
                  We will be creating unique client accounts based on all 4 RTA files
                </p>

                <p className="bg_clr_10 p-3 col-5 mr-2  text_2 ">
                  We will be mapping the existing UCCs to the client accounts so that you may
                  continue to use them.
                </p>
                <p className="bg_clr_10 p-3 col-5  text_2 ">
                  Clients with existing UCCs would be fit to transact through this software from day
                  zero. No on-boarding required.
                </p>
                <p className="bg_clr_7 p-3 col-5 mr-2  text_2 ">
                  We will be mapping the existing mandate IDs to the client accounts. You may
                  initiate a fresh SIP from this software using the same mandate.
                </p>
                <p className="bg_clr_7 p-3 col-5 text_2 ">
                  When the files are uploaded successfully, you will be able to see the reports the
                  next day. In case of an upload failure, please contact Support Team.
                </p>
              </div>
            )}
            <div className="row">
              <div className="col-6 text-right">
                <Button
                  text={showMoreGuide ? "Show less" : "Show More"}
                  size="sm"
                  onClick={() => this.showMore()}
                  customClass={"al_button_new_3"}
                />
              </div>
              {showUpload === false && (
                <div className="col-6">
                  <Button
                    text="Proceed to Upload"
                    size="sm"
                    onClick={() => this.showUpload()}
                    customClass={"al_button"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UploadRtas;
