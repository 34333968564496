import React, { Component, Fragment
  // , useEffect
 } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Fade from "react-bootstrap/Collapse";
import NavDropdown from "react-bootstrap/NavDropdown";
// import Nav from "react-bootstrap/Nav";
import Button from "../../components/Button/Button";

import FinappLogo from "../../assets/images/finappLogoNew.png";
import bar from "../../assets/images/bar.png";
import cross from "../../assets/images/cross.png";

import { getValue, setValue, clearAll } from "../../utils/sessionManager";
import { getNotification } from "../../actions/common";
import { CLIENT_NAV, SUB_ADVISOR_NAV, ADMIN_NAV, SUBADVISOR_CLIENT_LOGIN_NAV } from "./navList";

import { removeAlchemyServiceAuthToken } from "../../store/alchemyServiceAuth";
import { removePulseDbAuthToken } from "../../store/pulseDbAuth";

import "./Header.scss";

class NewHeader1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      user: {},
      isClient: false,
      logoUrl: "",
      isMenuOpen: false,
      navList: {},
      isMandatoryStepCompletedByClient: true,
    };
  }


  componentDidMount() {
    let { isMandatoryStepCompletedByClient } = this.state;
    const user = getValue("user");
    // let { isDefaultPasswordChanged, isUserPanCardUpdated, isUserAnswerQuestionnaire } = user;
    // if (!isDefaultPasswordChanged || !isUserPanCardUpdated || !isUserAnswerQuestionnaire) {
    //   isMandatoryStepCompletedByClient = false;
    // }
    
    let navList = {};
    if (user) {
      if (user.role === "SUBADVISOR") {
        navList = SUB_ADVISOR_NAV;
      } else if (user.role === "ADVISOR") {
        navList = ADMIN_NAV;
      } else if (user.role === "CLIENT") {
        navList = CLIENT_NAV;
      }
      this.setState(
        { isLogged: true, user, navList: navList, isMandatoryStepCompletedByClient },
        () => {
          this.updateState();
          this.getNotificationDetails();
        }
      );
    }
  }

  updateState = () => {
    const { user } = this.state;
    const { pathname } = this.props.location;
    const urls = pathname.split("/");
    const isAdvisorLogin = urls.find((item) => item === "advisor");
    if (isAdvisorLogin) {
      this.setState({ navList: SUB_ADVISOR_NAV, isClient: false });
    } else {
      if (user.role === "SUBADVISOR") {
        this.setState({ navList: SUBADVISOR_CLIENT_LOGIN_NAV ,isClient: true, user});
      }
    }
  };

  // for client notifion only

  getNotificationDetails = async () => {
    const { user } = this.state;
    if (user.role === "CLIENT") {
      try {
        const res = await getNotification();
        const newNotification =
          res.data.length > 0 ? res.data.filter((element) => element.is_viwed === false) : "";

        user.notification = newNotification;
        this.setState({ user });
      } catch (err) {
        console.error("getNotificationDetails, Server err: ", err.response);
        user.notification = 0;
        setValue("user", user);
        this.setState({ user });
      }
    }
  };

  // end for client notifion only

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      let user = getValue("user");
      this.setState(
        {
          user,
        },
        () => this.updateState(),
        () => this.getNotificationDetails()
      );
    }
  }


  logOut = (e) => {
    this.props.removePulseDbAuthToken();
    this.props.removeAlchemyServiceAuthToken();
    clearAll();
    this.setState({ isLogged: false });
    this.props.history.push("/login/");
  };

  render() {
    const {
      isLogged,
      user,
      navList,
      isClient,
      isMenuOpen,
      // isMandatoryStepCompletedByClient,
    } = this.state;
    // const subdomain = getValue("subdomain");
    const logoUrl = getValue("logoUrl");

    // let notification_number = JSON.parse(localStorage.getItem("user"));
    return (
      <Fragment>
        <div
          className="row align-items-center justify-content-between navbar_wrapper sticky-top m-0"
          style={{ backgroundColor: "#dbe8eb" }}
        >
          <div className="col-12 col-md-2">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <Link to="/">
                  <img
                    src={logoUrl ?? FinappLogo}
                    alt="logo"
                    // height="81.52px"
                    // width="170px"
                    className="brand_responsive_img"
                  />
                </Link>

                <div
                  className="d-flex d-md-none flex-row justify-content-around align-items-center"
                  style={{ width: "60%" }}
                >
                  {isLogged && (
                    <Fragment>
                      {user &&
                        navList.map((item) => {
                          return (
                            item.isIcon && (
                              <NavLink
                                to={item.path}
                                activeClassName="active"
                                // data-tooltip
                                title={item.hoverText}
                              >
                                <i className={`${item.icon} clr-y p-r`} aria-hidden="true"></i>
                              </NavLink>
                            )
                          );
                        })}
                    </Fragment>
                  )}
                </div>

                <img
                  src={isMenuOpen ? cross : bar}
                  alt="images"
                  style={{ width: "15.43px", height: "16.22px" }}
                  className="d-md-none"
                  onClick={() => this.setState({ isMenuOpen: !isMenuOpen })}
                />
                <Fade
                  in={isMenuOpen}
                  style={{
                    position: "fixed",
                    top: "80px",
                    width: "100%",
                  }}
                  className="d-md-none"
                >
                  <div id="example-fade-text">
                    <div className="d-flex flex-column  justify-content-center align-items-center d-md-none vertical_nav_wrapper">
                      {isLogged && user && (
                        <Fragment>
                          {navList.map((item) => {
                            return (
                              !item.isIcon &&
                              (item.isSubscriptionModal ? (
                                item.isSubscriptionActive() && (
                                  <NavLink
                                    className="nav_link text-nowrap"
                                    activeClassName="nav_link_active"
                                    key={item.key}
                                    to={item.path}
                                  >
                                    {item.value}
                                  </NavLink>
                                )
                              ) : (
                                <NavLink
                                  className="nav_link text-nowrap"
                                  activeClassName="nav_link_active"
                                  key={item.key}
                                  to={item.path}
                                >
                                  {item.value}
                                </NavLink>
                              ))
                            );
                          })}
                          {user && user.role === "SUBADVISOR" && (
                            <NavLink
                              className="nav_link text-nowrap"
                              activeClassName="nav_link_active"
                              key={"subadvisor_setting"}
                              to={"/subadvisor-setting"}
                            >
                              Settings
                            </NavLink>
                          )}

                          {user && user.role === "ADVISOR" && (
                            <NavLink
                              className="nav_link text-nowrap"
                              activeClassName="nav_link_active"
                              key={"admin_setting"}
                              to={"/admin-setting"}
                            >
                              Settings
                            </NavLink>
                          )}
                          <NavLink
                            className="nav_link text-nowrap"
                            activeClassName="nav_link_active"
                            key={"profile_details"}
                            to={"/profile-details"}
                          >
                            Profile
                          </NavLink>
                          <Button
                            text="Logout"
                            customClass="al_button_new"
                            onClick={() => this.logOut()}
                          />
                        </Fragment>
                      )}
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-lg-7 d-none d-md-block">
            <div className="d-flex flex-row justify-content-around align-items-center">
              {isLogged && user && (
                <Fragment>
                  {navList.map((item) => {
                    return (
                      !item.isIcon &&
                      (item.isSubscriptionModal ? (
                        item.isSubscriptionActive() && (
                          <NavLink
                            className="nav_link text-nowrap"
                            activeClassName="nav_link_active"
                            key={item.key}
                            to={item.path}
                          >
                            {item.value}
                          </NavLink>
                        )
                      ) : (
                        <NavLink
                          className="nav_link text-nowrap"
                          activeClassName="nav_link_active"
                          key={item.key}
                          to={item.path}
                        >
                          {item.value}
                        </NavLink>
                      ))
                    );
                  })}
                </Fragment>
              )}
            </div>
          </div>

          <div className="col-md-3 col-lg-3 d-none d-md-block">
            <div className="d-flex flex-row justify-content-end align-items-center">
              <div
                className="d-flex fle-row align-items-center justify-content-between"
                style={{ width: "80%" }}
              >
                {isLogged && (
                  <Fragment>
                    {user &&
                      navList.map((item) => {
                        return (
                          item.isIcon && (
                            <NavLink
                              to={item.path}
                              activeClassName="active"
                              // data-tooltip
                              title={item.hoverText}
                            >
                              <i className={`${item.icon} clr-y p-r`} aria-hidden="true"></i>
                            </NavLink>
                          )
                        );
                      })}

                    <NavDropdown
                      // title={user && user.username}
                      id="nav-dropdown-support"
                      className="nav_dropdown_item text-nowrap"
                      title={user && 
                        user.role === "CLIENT" || isClient === true  ? user.client_name : user.advisor_name}
                    >
                      {user && user.role === "SUBADVISOR" && (
                        <Fragment>
                          <NavDropdown.Item
                            as="button"
                            onClick={(e) => this.props.history.push("/profile-details")}
                          >
                            Profile
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            as="button"
                            onClick={(e) => this.props.history.push("/subadvisor-setting")}
                          >
                            Settings
                          </NavDropdown.Item>
                        </Fragment>
                      )}
                      {user && user.role === "ADVISOR" && (
                        <NavDropdown.Item
                          as="button"
                          onClick={(e) => this.props.history.push("/admin-setting")}
                        >
                          Settings
                        </NavDropdown.Item>
                      )}
                      {user && user.role === "CLIENT" && (
                        <NavDropdown.Item
                          as="button"
                          onClick={(e) => this.props.history.push("/profile-details")}
                        >
                          Profile
                        </NavDropdown.Item>
                      )}
                      {/* <NavDropdown.Item
                        as="button"
                        onClick={(e) => this.props.history.push("/profile-details")}
                      >
                        Profile
                      </NavDropdown.Item> */}
                      <NavDropdown.Item as="button" onClick={this.logOut} className="al_login_text">
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  removeAlchemyServiceAuthToken,
  removePulseDbAuthToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewHeader1));

// export default withRouter(Header);
