import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useLogNavigation = () => {
  const location = useLocation();

  useEffect(() => {
   // console.log('Navigated to:', location.pathname);
  }, [location]);
};

export default useLogNavigation;