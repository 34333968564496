import React, { Fragment, useState } from "react";
import Fade from "react-bootstrap/Collapse";

import { Link, withRouter, NavLink } from "react-router-dom";
import bar from "../../assets/images/bar.png";
import cross from "../../assets/images/cross.png";

import FinappLogo from "../../assets/images/finappLogoNew.png";
import "./NewHeader.scss";
import AdvisorSignUpModal from "../../containers/PreLoginAdvisor/AdvisorSignUpModal";
//import PreLoginAdvisorMain from '../../containers/PreLoginAdvisor/PreLoginAdvisorMain'

function PreloginAdvisorHeader(props) {
  const [isMenuOpen, setMenu] = useState(false);
  const [advisorSignup, setAdvisorSignup] = useState(false);
  return (
    <Fragment>
      <div className="row align-items-center justify-content-between navbar_wrapper sticky-top bg_7 m-0">
        <div className="col-12 col-md-2">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <Link to="/advisor">
                <img
                  src={FinappLogo}
                  alt="logo"
                  // height="81.52px"
                  // width="170px"
                  className="brand_responsive_img"
                />
              </Link>

              <img
                src={isMenuOpen ? cross : bar}
                alt="images"
                style={{ width: "15.43px", height: "16.22px" }}
                className="d-md-none"
                onClick={() => setMenu(!isMenuOpen)}
              />
            </div>
            <Fade
              in={isMenuOpen}
              style={{
                position: "fixed",
                top: "80px",
                width: "100%",
              }}
              className="d-md-none"
            >
              <div id="example-fade-text">
                <div className="d-flex flex-column  justify-content-center align-items-center d-md-none vertical_nav_wrapper">
                  <NavLink
                    to="/advisor-blog"
                    className="nav_link nav_link_button_1 text_clr_7 text-nowrap"
                    activeClassName="nav_link_active"
                  >
                    Blogs
                  </NavLink>
                  <NavLink
                    to=""
                    className="nav_link nav_link_button text_clr_7 text-nowrap"
                    activeClassName="nav_link_active"
                    onClick={() => setAdvisorSignup(true)}
                  >
                    Sign Up
                  </NavLink>
                </div>
              </div>
            </Fade>
          </div>
        </div>

        <div className="col-md-3 col-lg-3 d-none d-md-block">
          <div className="d-flex flex-row justify-content-around">
            <NavLink
              to="/advisor-blog"
              className="nav_link nav_link_button_1 text_clr_7 text-nowrap"
              activeClassName="nav_link_active"
            >
              Blogs
            </NavLink>

            <NavLink
              to="/advisor"
              className="nav_link nav_link_button text_clr_7 text-nowrap"
              activeClassName="nav_link_active"
              onClick={() => setAdvisorSignup(true)}
            >
              Sign Up
            </NavLink>
          </div>
        </div>
      </div>
      {advisorSignup && (
        <div className="row justify-content-center">
          <AdvisorSignUpModal show={advisorSignup} onHide={() => setAdvisorSignup(false)} />
        </div>
      )}
      {/* <PreLoginAdvisorMain advisorSignup={advisorSignup}/> */}
    </Fragment>
  );
}

export default withRouter(PreloginAdvisorHeader);
