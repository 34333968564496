import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//import MainHeader from "../components/Header/MainHeader";
import NewMainHeader from "../components/Header/NewMainHeader";
// import PreloginAdvisorHeader from "../components/Header/PreloginAdvisorHeader";
// import NotVerifiedDomainHeader from "../components/Header/NotVerifiedDomainHeader";
import UseLogNavigation from './useLogNavigation';
import { verifySubdomain } from "../../src/actions/advisor";
import { getPresignedUrlToGetLogo } from "../actions/docUlpoad";
import { setValue } from "../utils/sessionManager";

const MainLayout = (props) => {
  UseLogNavigation()
  const history = useHistory();

  const [subdomain, setSubdomain] = useState("");
  const [logoUrl, setlogoUrl] = useState("");

  const [isSubdomainVerified, setSubdomainVerified] = useState(false);

  async function verifyDSubdomain() {
    const host = window.location.host;

    const hostArr = host.includes("localhost")
  ? host.split(".").slice(0, -1)
  : host.includes("finapp")
  ? host.split(".").slice(0, -2)
  : [host];

      console.log(hostArr,"hostArr",host)
    // arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
    // arr = host.split(".").slice(0, host.includes("finapp") ? -2 : -3);
    if (hostArr.length > 0) {
      let values = {
        subdomain: hostArr[0],
      };
      try {
        const res = await verifySubdomain(values);
        setValue("advisor_id", res.data.advisor_id);
        if (res.isVerified) {
          if (res.data.logo_s3_key) {
            let details = {
              logo_s3_key: res.data.logo_s3_key,
              logo_file_type: res.data.logo_file_type,
            };
            const res1 = await getPresignedUrlToGetLogo(details);
            setValue("logoUrl", res1.data.url);
            setlogoUrl(res1.data.url);
          }
          setValue("isSubdomainVerified", res.isVerified);
          setSubdomain(hostArr[0]);
          // setSubdomainVerified(res.isVerified);
          setValue("subdomain", hostArr[0]);
          setValue("advisorId", res.data.advisor_id);
        } else {
          history.push("/subdomain_not_verified");
        }
      } catch (err) {
        console.error("verifyDSubdomain, Server err: ", err);
      }
    } else {
      setSubdomainVerified(true);
      setValue("subdomain", "");
      setValue("logoUrl", "");
    }
  }

  useEffect(() => {
    verifyDSubdomain();
  }, []);
  return (
    <Fragment>
      {/* <MainHeader subdomain={subdomain} logoUrl={logoUrl} /> */}
      <NewMainHeader subdomain={subdomain} logoUrl={logoUrl} />
      {/* {subdomain !== "" && <NewMainHeader subdomain={subdomain} logoUrl={logoUrl} />} */}
      {/* {subdomain == "" && <PreloginAdvisorHeader subdomain={subdomain} logoUrl={logoUrl} />} */}

      <div>{props.children}</div>
      {/* {isSubdomainVerified && (
        <Fragment>
          <MainHeader />
          <div>{props.children}</div>
        </Fragment>
      )}
      {!isSubdomainVerified && (
        <Fragment>
          <NotVerifiedDomainHeader />
          <div>{props.children}</div>
        </Fragment>
      )} */}
    </Fragment>
  );
};

export default MainLayout;
