import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import config from "../config";
// import { isExpired } from "../utils/sessionManager";

// const isLoggedIn = () => {
//   if (isExpired("token")) {
//     return true;
//   } else {
//     return false;
//   }
// };

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isPulseDbTokenExpired = useSelector(
    (state) => state.pulseDbAuthToken.isPulseDbTokenExpired
  );
  const isalchemyServiceTokenExpired = useSelector(
    (state) => state.alchemyServiceAuthToken.isAlchemyServiceTokenExpired
  );

  let isLoggedIn = "";
  if (config.node_env === "production") {
    isLoggedIn = isalchemyServiceTokenExpired && isPulseDbTokenExpired;
  } else {
    isLoggedIn = isPulseDbTokenExpired;
  }
  return (
    // <Route
    //   {...rest}
    //   render={(props) => (isLoggedIn() ? <Component {...props} /> : <Redirect to="/login" />)}
    // />
    <Route
      {...rest}
      render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
};

export default PrivateRoute;
