import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import config from "../../config";
// import Button from "../Button/Button";
//import PreLoginInvestment from "../../containers/Home/PreLoginInvestment";
// import Navbar from 'react-bootstrap/Navbar';

class NotVerifiedDomainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <nav className="al_navbar navbar navbar-expand-lg navbar-light bg_7">
          <span className="navbar-brand">
            <Link to="/">
              <img src={config.s3_url + "/favicon.png"} alt="logo" height="25px" />
              <span className="nav_logo_text text-white">FinApp</span>
            </Link>
          </span>
        </nav>
      </Fragment>
    );
  }
}

export default withRouter(NotVerifiedDomainHeader);
