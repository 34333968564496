let envVariables = {};
if (process.env.NODE_ENV === "production") {
  envVariables = {
    node_env: `${process.env.REACT_APP_NODE_ENV}`,
    base_url: `${process.env.REACT_APP_base_url}`,
    s3_url: `${process.env.REACT_APP_s3_url}`,
    s3_url_dream: `${process.env.REACT_APP_s3_url_dream}`,
    base_url_2: `${process.env.REACT_APP_base_url_2}`,
    base_url_3: `${process.env.REACT_APP_base_url_3}`,
    base_url_4: `${process.env.REACT_APP_base_url_4}`,
    base_url_5: `${process.env.REACT_APP_base_url_5}`,
    base_url_6: `${process.env.REACT_APP_base_url_6}`,
    base_url_7: `${process.env.REACT_APP_base_url_7}`,
    base_url_8: `${process.env.REACT_APP_base_url_8}`,
    bse_user_id: `${process.env.REACT_APP_bse_user_id}`,
    bse_member_id: `${process.env.REACT_APP_bse_member_id}`,
    bse_password: `${process.env.REACT_APP_bse_password}`,
    bse_pass_key: `${process.env.REACT_APP_bse_pass_key}`,
    partner: `${process.env.REACT_APP_partner}`,
    partner_key: `${process.env.REACT_APP_partner_key}`,
    partner_1: `${process.env.REACT_APP_partner_1}`,
    partner_key_1: `${process.env.REACT_APP_partner_key_1}`,
  };
} else if (process.env.NODE_ENV === "staging") {
  envVariables = {
    node_env: `${process.env.REACT_APP_NODE_ENV}`,
    base_url: `${process.env.REACT_APP_base_url}`,
    s3_url: `${process.env.REACT_APP_s3_url}`,
    s3_url_dream: `${process.env.REACT_APP_s3_url_dream}`,
    base_url_2: `${process.env.REACT_APP_base_url_2}`,
    base_url_3: `${process.env.REACT_APP_base_url_3}`,
    base_url_4: `${process.env.REACT_APP_base_url_4}`,
    base_url_5: `${process.env.REACT_APP_base_url_5}`,
    base_url_6: `${process.env.REACT_APP_base_url_6}`,
    base_url_7: `${process.env.REACT_APP_base_url_7}`,
    base_url_8: `${process.env.REACT_APP_base_url_8}`,
    bse_user_id: `${process.env.REACT_APP_bse_user_id}`,
    bse_member_id: `${process.env.REACT_APP_bse_member_id}`,
    bse_password: `${process.env.REACT_APP_bse_password}`,
    bse_pass_key: `${process.env.REACT_APP_bse_pass_key}`,
    partner: `${process.env.REACT_APP_partner}`,
    partner_key: `${process.env.REACT_APP_partner_key}`,
    partner_1: `${process.env.REACT_APP_partner_1}`,
    partner_key_1: `${process.env.REACT_APP_partner_key_1}`,
  };
} else {
  envVariables = {
    node_env: `${process.env.REACT_APP_NODE_ENV}`,
    base_url: `${process.env.REACT_APP_base_url}`,
    s3_url: `${process.env.REACT_APP_s3_url}`,
    s3_url_dream: `${process.env.REACT_APP_s3_url_dream}`,
    base_url_2: `${process.env.REACT_APP_base_url_2}`,
    base_url_3: `${process.env.REACT_APP_base_url_3}`,
    base_url_4: `${process.env.REACT_APP_base_url_4}`,
    base_url_5: `${process.env.REACT_APP_base_url_5}`,
    base_url_6: `${process.env.REACT_APP_base_url_6}`,
    base_url_7: `${process.env.REACT_APP_base_url_7}`,
    base_url_8: `${process.env.REACT_APP_base_url_8}`,
    bse_user_id: `${process.env.REACT_APP_bse_user_id}`,
    bse_member_id: `${process.env.REACT_APP_bse_member_id}`,
    bse_password: `${process.env.REACT_APP_bse_password}`,
    bse_pass_key: `${process.env.REACT_APP_bse_pass_key}`,
    partner: `${process.env.REACT_APP_partner}`,
    partner_key: `${process.env.REACT_APP_partner_key}`,
    partner_1: `${process.env.REACT_APP_partner_1}`,
    partner_key_1: `${process.env.REACT_APP_partner_key_1}`,
    google_analytics_tracking_id:`${process.env.REACT_APP_google_analytics_tracking_id}`
  };
}

export default envVariables;

// let envVariables = {};
// if (process.env.NODE_ENV === "production") {
//   envVariables = {
//     base_url: "https://alchemy-service.pulselabs.co.in",
//     s3_url: "https://s3.ap-south-1.amazonaws.com/alchemy-dev-v1",
//     s3_url_dream: "https://s3.ap-south-1.amazonaws.com/pulse-dreams-view",
//     base_url_2: "https://pulsedb-qa.pulselabs.co.in",
//     base_url_3: "https://i9xz8jy144.execute-api.ap-south-1.amazonaws.com",
//     base_url_4: "https://api-mf-trans-dev.pulselabs.co.in",
//     base_url_5: "https://matrisq.pulselabs.co.in",
//     base_url_6: "https://matrisq-qa.pulselabs.co.in",
//     bse_user_id: "MANISH1",
//     bse_member_id: "PULSLAB1",
//     bse_password: "@123456",
//     bse_pass_key: "Pulselab",
//   };
// } else {
//   // development
//   envVariables = {
//     base_url: "https://alchemy-service.pulselabs.co.in",
//     s3_url: "https://s3.ap-south-1.amazonaws.com/alchemy-dev-v1",
//     s3_url_dream: "https://s3.ap-south-1.amazonaws.com/pulse-dreams-view",
//     base_url_2: "https://pulsedb-qa.pulselabs.co.in",
//     base_url_3: "https://i9xz8jy144.execute-api.ap-south-1.amazonaws.com",
//     base_url_4: "http://localhost:3100",
//     //base_url_4: "https://api-mf-trans-dev.pulselabs.co.in",
//     base_url_5: "https://matrisq.pulselabs.co.in",
//     base_url_6: "https://matrisq-qa.pulselabs.co.in",
//     bse_user_id: "MANISH1",
//     bse_member_id: "PULSLAB1",
//     bse_password: "@123456",
//     bse_pass_key: "Pulselab",
//   };
// }

// export default envVariables;
