import Validator from 'validatorjs';

const errorMessages = {
  required: ':attribute is required.',
  numeric: ':attribute must be a number.',
  email: ':attribute format is invalid.',
  alpha_num: ':attribute must be alphanumeric.',
  digits: ':attribute must be :digits digits.',
  regex: ':attribute format is invalid.'
};


export const singleFieldValidation = (rules, { key, value }) => {
  const validationResponse = { isValid: true };
  if (rules[key]) {
    const validation = new Validator({ [key]: value }, { [key]: rules[key] }, errorMessages);
    validationResponse.isValid = validation.passes();
    if (!validationResponse.isValid) {
      validationResponse.errors = validation.errors.all();
    }
  }
  return validationResponse;
};

export const allFieldsValidation = (rules, data) => {
  const validation = new Validator(data, rules, errorMessages);
  const validationResponse = { isValid: validation.passes() };
  if (!validationResponse.isValid) {
    validationResponse.errors = validation.errors.all();
  }
  return validationResponse;
};

export const allArrayFieldsValidation = (rules, dataArr) => {
  const arrLength = dataArr.length;
  let allErrors = [];
  for(let i = 0; i < arrLength; i++){
    const validation = new Validator(dataArr[i], rules, errorMessages);
    const validationResponse = { isValid: validation.passes() };
    if (!validationResponse.isValid) {
      validationResponse.errors = validation.errors.all();
    }
    allErrors.push(validationResponse)
  }
  return allErrors;
};

export const waitTime = 500;
