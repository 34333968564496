import config from "../config";
import { authApiCalls, apiCalls } from "./apiCalls";
import { getValue } from "../utils/sessionManager";

// import { getValue } from "../utils/sessionManager";

export const getPresignedUrlToUploadDocs = async (values) => {
  const url = config.base_url_4 + `/api/v1/common/get_presigned_url_upload_docs`;
  const reqObject = {
    file_type: values.file_type,
  };
  try {
    const result = await apiCalls("post", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getPresignedUrlToUploadDocs, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getPresignedUrlToDownloadDocs = (values) => {
  const url = config.base_url_4 + `/api/v1/common/get_presigned_url_download_docs`;
  // const reqObject = {
  //   file_name: values.file_name,
  //   file_type: values.file_type,
  // };
  const reqObject = {
    s3_key: values.s3_key,
    file_type: values.file_type,
  };
  return authApiCalls("post", url, reqObject);
};

export const saveDocsToS3UsingPresignedUrl = (file, pressignedUrl) => {
  const url = pressignedUrl;
  const docs = file;
  return authApiCalls("put", url, docs);
};

export const createNewMfAccount = async (value) => {
  const url = config.base_url_4 + "/api/v1/common/save_mf_account";
  const user = getValue("user");
  const reqObject = {
    client_id: user.client_id,
    pancard_file_name: value.signature_file_name,
    pancard_file_type: value.signature_file_type,
    pancard_s3_key: value.signature_s3_key,
    // cacncelled_cheque_file_name: value.cacncelled_cheque_file_name || null,
    // cancelled_cheque_file_type: value.cacncelled_cheque_file_type || null,
    // cancelled_cheque_s3_key: value.cancelled_cheque_s3_key || null,
  };
  try {
    const result = await apiCalls("post", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("createNewMfAccount, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getPresignedUrlToUploadLogoInS3Bucket = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/get_presigned_url_upload_logo`;
  const reqObject = {
    file_type: values.file_type,
    file_categ: values.file_categ,
  };
  try {
    const result = await apiCalls("post", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getPresignedUrlToUploadLogo, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const getPresignedUrlToGetLogo = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/get_presigned_url_get_logo`;

  // const reqObject = {
  //   s3_key: "c283454a-89cb-4880-936c-931f0b7a8c41",
  //   file_type: values.file_type,
  //   file_categ: values.file_categ,
  // };
  const reqObject = {
    s3_key: values.logo_s3_key,
    file_type: values.logo_file_type,
    file_categ: "company_logo",
  };
  return authApiCalls("post", url, reqObject);
};

export const getPrsignedUrlToDeleteS3BucketObject = async (values) => {
  const url = config.base_url_4 + `/api/v1/advisor/get_presigned_url_delete_obj`;

  const reqObject = {
    s3_key: values.s3_key,
    file_type: values.file_type,
    file_categ: values.file_categ,
  };
  try {
    const result = await authApiCalls("post", url, reqObject);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getPrsignedUrlToDeleteS3BucketObject, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const deleteS3BucketObject = async (pressignedUrl) => {
  const url = pressignedUrl;
  return authApiCalls("delete", url, {});
};
