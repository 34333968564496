import { configureStore } from "@reduxjs/toolkit";
import alchemyServiceAuthReducer from "./alchemyServiceAuth";
import pulseDbAuthReducer from "./pulseDbAuth";

const store = configureStore({
  reducer: {
    alchemyServiceAuthToken: alchemyServiceAuthReducer,
    pulseDbAuthToken: pulseDbAuthReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

// import { createStore } from "redux";

// const counterReducer = (state = { testingPersonName: "shubham" }, action) => {
//   if (action.type === "set") {
//     return {
//       testingPersonName: "sandeep",
//     };
//   }

//   return state;
// };

// const store = createStore(counterReducer);

// export default store;
